import { fetchProducts, updateProduct } from '@/api/product'
import { useTableOptions } from '@/composables'
import { t } from '@/plugins/i18n'
import { computed, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useProductList(query = {}) {
  const productListTable = ref([])

  const tableColumns = [
    { text: 'IMAGE', value: 'media', sortable: false },
    { text: 'ID', value: 'product_number' },
    { text: 'PRODUCT', value: 'product', sortable: false },
    { text: 'PRICE', value: 'max_sku_price' },
    { text: 'CATEGORY', value: 'category', sortable: false },
    { text: 'MERCHANT', value: 'merchant', sortable: false },
    { text: 'STATUS', value: 'status' },
    { text: 'TAGS', value: 'tag', sortable: false },
    { text: 'UPDATED AT', value: 'updated_at' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
    },
  )
  const searchQuery = ref(cached[1] || '')
  const natureFilter = ref(cached[2] || null)
  const categoryFilter = ref(cached[3] || null)
  const statusFilter = ref(cached[4] || null)
  const tagFilter = ref(cached[5] || null)
  const merchantFilter = ref(cached[6] || null)
  const minSkuPriceFromFilter = ref(cached[7] || null)
  const minSkuPriceToFilter = ref(cached[8] || null)
  const channelNoteFilter = ref(cached[9] || null)
  const brandIdFilter = ref(cached[10] || { brand_id: null })
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])

  // must follow cached[0...4] variable order above.
  const productOptions = computed(() =>
    productListTable.value.map(p => ({
      title: t(p.title),
      value: p.id,
    })),
  )

  // fetch data
  const loadProducts = query2 =>
    fetchProducts(
      useTableOptions(options.value, {
        search: searchQuery.value,
        status: statusFilter.value,
        nature: natureFilter.value,
        category_ids: categoryFilter.value ? [categoryFilter.value] : undefined,
        tag_ids: tagFilter.value ? [tagFilter.value] : undefined,
        merchantId: merchantFilter.value,
        minSkuPriceFrom: minSkuPriceFromFilter.value || undefined,
        minSkuPriceTo: minSkuPriceToFilter.value || undefined,
        channelNoteKey: channelNoteFilter.value,
        ...brandIdFilter.value,
        ...query,
        ...query2,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        productListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false

        return records
      })
      .catch(error => {
        console.log(error)
      })

  const updateAllProducts = async updates => {
    loading.value = true
    for (const product of selectedRows.value) {
      const data = { ...updates }
      await updateProduct(product.id, data)
    }
    loadProducts()
    loading.value = false
    selectedRows.value = []
  }
  const cachable = [
    options,
    searchQuery,
    natureFilter,
    categoryFilter,
    statusFilter,
    tagFilter,
    merchantFilter,
    minSkuPriceFromFilter,
    minSkuPriceToFilter,
    channelNoteFilter,
    brandIdFilter,
  ]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }
      loading.value = true
      selectedRows.value = []
      loadProducts()
      cache()
    }, 300),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'draft') return 'warning'
    if (status === 'published') return 'success'
    if (status === 'unpublished') return 'danger'

    return 'primary'
  }

  return {
    productListTable,
    tableColumns,
    searchQuery,
    statusFilter,
    natureFilter,
    categoryFilter,
    tagFilter,
    merchantFilter,
    minSkuPriceFromFilter,
    minSkuPriceToFilter,
    channelNoteFilter,
    brandIdFilter,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadProducts,
    productOptions,
    updateAllProducts,
    resolveStatusVariant,
  }
}
